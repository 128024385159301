.content {
    padding: var(--spacing-m) var(--spacing-l);
    margin-top: var(--spacing-xl);
}

@media only screen and (max-width: 600px) {
    .content {
        width: 100%;
        margin-left: 30px;
        margin-right: 30px;
    }
}

.content2 {
    padding: 30px 0px;
    background-color: initial;
}

@media only screen and (max-width: 600px) {
    .content2 {
        width: 100%;
        margin-left: 35px;
        margin-right: 35px;
    }
}

.title {
    color: #131f3e;
    font-size: 20px;
    font-weight: 700;
    margin-top: -5px;
}

.labels {
    font-weight: 350;
    color: #000;
    font-size: 14px;
    font-weight: 520;
}

.securityLevel {
    font-size: 5px;
    font-weight: 325;
    margin-left: -30px;
}
