.shoppingBtn {
    height: 35px;
    background-color: rgba(74, 34, 212, 0.15);
    color: #4A22D4;
}

.shoppingBtn:hover {
    background-color: rgba(74, 34, 212, 0.35);
}

.spacingM{
    padding: var(--spacing-m) var(--spacing-l);
}

.number {
    color: #FF007A; 
    padding-right: 5px;
}

.whiteButton {
    color: #4A22D4;
    background-color: #fff;
    border: 1px solid #4A22D4;
    border-radius: 10px;
    white-space: nowrap;
    justify-content: space-between;
}

.whiteButton:hover {
    background-color: #4A22D4;
    color: #fff;
}

.list > li {
    border-radius: 15px;
    background: #fff;
    margin-bottom: 5px;
}