.title {
    color: #4A22D4;
    margin-left: 60px;
}

.resumeCard {
    background-color: rgba(243, 244, 245, 1);
    padding-left: 45px;
    padding-right: 120px;
}

.divider {
     border-width: 1;
     background: #000
}

.spacing {
    margin-left: 80px;
    margin-right: 80px;
}
